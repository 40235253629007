import React from "react";
import CounterUpOne from "../counterup/CounterUpOne";

const HomeThreeCourse = () => {
  return (
    <div className="edu-counterup-and-course-area">
      <CounterUpOne />
    </div>
  );
};

export default HomeThreeCourse;
