import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import HomeTwoService from "../../components/home-two/HomeTwoService";

const CourseOne = () => {
  //   const CourseItems = CourseData.slice(0, 9);
  return (
    <>
      <SEO title="Hizmelerimiz - Mor Kelebek Ajans" />
      <Layout>
        <BreadcrumbOne
          title="Hizmetlerimiz"
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl="Hizmetlerimiz"
        />
        <HomeTwoService />
      </Layout>
    </>
  );
};

export default CourseOne;
