import React from "react";
import { Link } from "react-router-dom";
import ScrollTopButton from "./ScrollTopButton";
import { RiInstagramFill } from "react-icons/ri";

const FooterOne = () => {
  return (
    <>
      <footer className="eduvibe-footer-one edu-footer footer-style-default">
        <div className="footer-top">
          <div className="container eduvibe-animated-shape">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="edu-footer-widget">
                  <div className="logo">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      <img
                        className="logo-light"
                        src="/images/logo/logo.png"
                        alt="Footer Logo"
                        // style={{ backgroundColor: "orchid" }}
                      />
                    </Link>
                  </div>
                  {/* <p className="description">
                    Mor Kelebek Ajans 2014 yılından beri müşterilerine Web
                    Tasarım, Web yazılım, Grafik Tasarım, Toplu Mail Gönderimi,
                    Sosyal Medya Yönetimi,Video Prodüksiyon, Fotağraf Çekimi Seo
                    ve E-Ticaret uygulamaları konusunda profesyonel olarak
                    hizmet vermektedir.
                  </p> */}{" "}
                  <h6 style={{ color: "white", textAlign: "center" }}>
                    Sosyal Medya Hesaplarımız
                  </h6>
                  <ul
                    className="social-share"
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/Morkelebekajans/  "
                        target="_blank"
                        without
                        rel="noreferrer"
                      >
                        <i className="icon-Fb"></i>
                      </a>
                      <p>Facebook</p>
                    </li>

                    <li>
                      <a
                        href="https://www.linkedin.com/in/morkelebekajans/ "
                        target="_blank"
                        without
                        rel="noreferrer"
                      >
                        <i className="icon-linkedin"></i>
                      </a>
                      <p>Linkedin</p>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/morkelebekajans"
                        target="_blank"
                        without
                        rel="noreferrer"
                      >
                        <RiInstagramFill />
                      </a>
                      <p>İnstagram</p>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/morkelebekajans"
                        target="_blank"
                        without
                        rel="noreferrer"
                      >
                        <i className="icon-Twitter"></i>
                      </a>
                      <p>Twitter</p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget explore-widget">
                  <h5 className="widget-title">Keşfet</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/hakkimizda">
                          <i className="icon-Double-arrow"></i>Hakkımızda
                        </Link>
                      </li>

                      <li>
                        <Link to="/blog">
                          <i className="icon-Double-arrow"></i>Blog & Haberler
                        </Link>
                      </li>

                      <li>
                        <Link to="/referanslar">
                          <i className="icon-Double-arrow"></i>Referanslarımız
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget quick-link-widget">
                  <h5 className="widget-title">Faydalı Linkler</h5>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/bize-ulasin">
                          <i className="icon-Double-arrow"></i>Bize Ulaşın
                        </Link>
                      </li>

                      <li>
                        <Link to="/blog-detay/3">
                          <i className="icon-Double-arrow"></i>Sosyal Medya
                          Yönetimi
                        </Link>
                      </li>
                      <li>
                        <Link to="/blog-detay/4">
                          <i className="icon-Double-arrow"></i>Seo Çalışması
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="edu-footer-widget">
                  <h5 className="widget-title">İletişim Bilgilerimiz</h5>
                  <div className="inner">
                    <div className="widget-information">
                      <ul className="information-list">
                        <li>
                          <i className="icon-map-pin-line"></i>Mehmet Akif Ersoy
                          Mah. Dosima Plaza, 325.Sk, No:3/ 54 (6.kat),
                          06200-Yenimahalle/ANKARA
                        </li>

                        <li>
                          <i className="icon-phone-fill"></i>
                          <a href="tel: + 90 (552) 676-1100">
                            + 90 552 676 11 00
                          </a>
                        </li>
                        <li>
                          <i className="icon-mail-line-2"></i>
                          <a
                            target="_blank"
                            href="mailto:morkelebekajans@gmail.com"
                            without
                            rel="noreferrer"
                          >
                            morkelebekajans@gmail.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="shape-dot-wrapper shape-wrapper d-md-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-21-01.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-35.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area copyright-default">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Mor Kelebek Ajans 2014 yılından beri müşterilerine Web
                    Tasarım, Web yazılım, Grafik Tasarım, Toplu Mail Gönderimi,
                    Sosyal Medya Yönetimi,Video Prodüksiyon, Fotağraf Çekimi Seo
                    ve E-Ticaret uygulamaları konusunda profesyonel olarak
                    hizmet vermektedir.
                  </p>
                  <p>
                    Copyright 2024{" "}
                    <a href="https://morkelebekajans.com/">Mor Kelebek Ajans</a>{" "}
                    . Tüm Hakları Saklıdır.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTopButton />
    </>
  );
};

export default FooterOne;
