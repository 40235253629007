import React from "react";
import SEO from "../../common/SEO";
import HeaderTwo from "../../common/header/HeaderTwo";
import BannerThree from "../../components/banner/BannerThree";
import AboutFive from "../../components/about/AboutFive";
import HomeThreeCourse from "../../components/home-three/HomeThreeCourse";
import TestimonialSectionTwo from "../../components/testimonial-section/TestimonialSectionTwo";
import NewsLetterFormTwo from "../../components/newsletter/NewsLetterTwo";
import FooterOne from "../../common/footer/FooterOne";
import HomeTwoService from "../../components/home-two/HomeTwoService";

const HomeThree = () => {
  return (
    <>
      <SEO title="Mor Kelebek Ajans Anasayfa" />

      <HeaderTwo styles="header-transparent header-style-2" />

      <BannerThree />

      <AboutFive />

      <HomeThreeCourse />

      <HomeTwoService />

      <TestimonialSectionTwo />

      <NewsLetterFormTwo />

      <FooterOne />
    </>
  );
};

export default HomeThree;
