import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import TestimonialSectionFour from "../../components/testimonial-section/TestimonialSectionFour";
import TestimonialSectionTwo from "../../components/testimonial-section/TestimonialSectionTwo";

const Testimonial = () => {
  return (
    <>
      <SEO title="Referanslar - Mor Kelebek Ajans" />
      <Layout>
        <BreadcrumbOne
          title="Referanslar"
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl="Referanslar"
        />

        <TestimonialSectionFour />

        <TestimonialSectionTwo />
      </Layout>
    </>
  );
};

export default Testimonial;
