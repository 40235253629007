import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import AboutFive from "../../components/about/AboutFive";
import HomeFiveProgress from "../../components/home-five/HomeFiveProgress";
import AboutUsThreeMission from "../../components/about-us-three/AboutUsThreeMission";
import CounterUpOne from "../../components/counterup/CounterUpOne";
import TestimonialSectionTwo from "../../components/testimonial-section/TestimonialSectionTwo";

const AboutUsThree = () => {
  return (
    <>
      <SEO title="Mor Kelebek Ajans Hakkımızda" />
      <Layout>
        <BreadcrumbOne
          title="Hakkımızda"
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl="Hakkımızda"
        />
        <AboutUsThreeMission />
        <AboutFive wrapperClass="edu-section-gapTop" />

        <HomeFiveProgress />

        <CounterUpOne />

        <TestimonialSectionTwo classes="counterup-overlay-top" />
      </Layout>
    </>
  );
};

export default AboutUsThree;
