import ürünfoto from "./urunfoto.png";
import kurumsalkimlik from "./kurumsal_kimlik.png";
import sosyalmedya from "./sosyal-medya-yonetimi.jpg";
import seo from "./seo.png";
import eticaret from "./e-ticaret.jpg";
import dijitalpazarlama from "./dijital-pazarlama-danismanligi.png";

const BlogData = [
  {
    id: 1,
    title: "Ürün Fotoğraf Çekimi",
    image: ürünfoto,
    date: "06 Kasım, 2023",
    shortDate: "06 Kasım",
    // author: ["John Kite"],
    readingTime: "3 Dk Okuma Süresi",
    view: "232 Görüntüleme",
    comment: "02 Yorum",
    categories: ["Fotoğraf ve Video Çekimi"],
    tags: ["Fotoğraf Çekimi", "Ürün Çekimi", "Reklam"],
    details:
      "İnternet üzerinden ürünlerinizi satmayı planlıyorsanız, ürünlerinizin fotoğraflarının kalitesi müşterinin satın almaya karar vermesi için belirleyici bir faktördür.",
    desc: "İnternet üzerinden ürünlerinizi satmayı planlıyorsanız, ürünlerinizin fotoğraflarının kalitesi müşterinin satın almaya karar vermesi için belirleyici bir faktördür. Bir resim bin kelimeye bedeldir, ifadesini duymuş olabilirsiniz. Amaç bir ürün satmaksa, bu çok yeterli bir açıklama olarak kabul edilebilir. İyi çekilmiş bir fotoğraf, ürün hakkında önemli detayları çok güzel bir şekilde aktarabilir.",
    desctitle1: "Ürün fotoğraf çekimleri",
    desc1:
      "Ürünleri gösteren iyi fotoğraflar, müşterinin en küçük ayrıntılarını bilmesi için önemlidir. Ancak fotoğrafların başka önemli görevi de vardır: satın almak isteği uyandırmaya ve satın almayı teşvik etmeye yönlendirirler",
    bloqnote:
      "Mor Kelebek Ajans olarak bu konuda size gerekli altyapı ve çözümleri bir paket olarak sunmakta bütün hizmet adımlarına tek bir noktadan ulaşmanızı sağlamaktadır.",

    desctitle2: "Büyük Bir Stüdyo Yerine Kurumsal Ürün Çekimlerini Deneyin. ",
    desc2:
      "Ürünleri gösteren iyi fotoğraflar, müşterinin en küçük ayrıntılarını bilmesi için önemlidir. Ancak fotoğrafların başka önemli görevi de vardır: satın almak isteği uyandırmaya ve satın almayı teşvik etmeye yönlendirirler",
    desc3:
      "Yüksek kalitede fotoğraflara ihtiyacınız varsa, ideal, ürünün tüm ayrıntılarını içeren görüntülere sahip bir DSLR kamera olacaktır. Bu, herhangi bir görüntü sıkıştırması olmadan RAW formatını kullandıkları için harika bir sonuç almış olacaksınız. Dosyalar normal bir JPEG’den biraz daha büyük olacaktır, ancak daha sonra düzenlemeniz gerekirse daha yüksek bir kaliteye sahip olmuş olacaksınız. Yüksek kaliteli fotoğraflar hakkında konuştuğumuzu ve aynı zamanda bunu nasıl yapabileceğinizi merak etmiş olabilirsiniz. DSLR fotoğraf makinesi satın alarak başlayabilir ve daha sonra tripodlar, flaşlar ve fotoğrafçıların sürekli olarak kullandığı diğer öğeler gibi lenslere ve aksesuarlara yatırım yapabilirsiniz. Bu sizin için oldukça maliyetli bir durum olabilir. Bunun için şirketinize bir stüdyo kurmanıza ihtiyaç yoktur. Bu konuda size yardımcı olacağımızdan emin bir ekibimiz var ve Ürün Fotoğraf Çekim Hizmeti konusunda birçok mutlu müşteriye sahibiz",
    listtitle: "Hangi Alanlarda Ürün Çekimleri Yapıyoruz?",
    list1: "Takı Sektörü için Ürün Fotoğrafçılığı",
    list2: "Yemek ve Gıda için Ürün Fotoğrafçılığı",
    list3: "Mobilya ve Aksesuar için Ürün Fotoğrafçılığı",
    list4: "E-Ticaret için Ürün Fotoğrafçılığı",
    prevUrl: "/blog-detay/6",
    nextUrl: "/blog-detay/2",
    prevBlog: "Dijital Pazarlama",
    nextBlog: "Kurumsal Kimlik Çalışması",
  },

  {
    id: 2,
    title: "Kurumsal Kimlik Çalışması",
    image: kurumsalkimlik,
    date: "10 Ekim, 2023",
    shortDate: "10 Ekim",
    // author: ["Hazel Thomas"],
    readingTime: "4 Dk Okuma Süresi",
    view: "135 Görüntüleme",
    comment: "03 Yorum",
    categories: ["Kurumsal Kimlik", "Reklam Kampanyası"],
    tags: ["Kurumsal Kimlik", "Marka"],
    details:
      "Müşterinize verdiğiniz kartvizit, yazışmalarınızda kullandığınız antetli kağıtlarınız ve zarflarınız, gönderdiğiniz faturalar, kaşeniz, dosyalarınız ve firmanıza özel tüm diğer basılı materyaller, müşterilerinizin gözünde kurum kimliğinizi oluşturur.",
    desc: "Müşterinize verdiğiniz kartvizit, yazışmalarınızda kullandığınız antetli kağıtlarınız ve zarflarınız, gönderdiğiniz faturalar, kaşeniz, dosyalarınız ve firmanıza özel tüm diğer basılı materyaller, müşterilerinizin gözünde kurum kimliğinizi oluşturur.",
    desctitle1: "",
    desc1: "",
    bloqnote:
      "Tasarım süreçlerimizin başlangıcından itibaren tüm çalışmalara sizi de dahil ederek, sizin için özel olarak kurgulanmış kurumsal kimlik tasarımlarınızı, yıllarca birçok firmaya verdiğimiz hizmetler ile edindiğimiz tecrübemizi sizin için kullanarak hazırlıyoruz.",
    desctitle2: "",
    desc2: "  ",
    desc3: "",
    listtitle: "Kurumsal kimlik tasarımı nedir, neden önemlidir",
    list1:
      "Günümüzde birçok sektörde rekabet çok yüksek seviyelerde ve müşterilerin gözünde, firmanızın rakiplerinizden ayrılabilmesinin artık ürün ve hizmet kalitesini üst seviyede tutmaya ek, başka alanlarda da farklılıklar yaratmalısınız.",
    list2:
      "Kullandığınız materyaller ve kurumsal kimlik tasarımınız, ürün ve hizmetlerinizin kalitesine ait önemli bir göstergedir.",
    list3:
      "Siz ürün ve hizmetlerinizin kalitesi ve müşteri memnuniyeti ile ilgilenirken, biz de sizin için, firmanızı en iyi şekilde temsil edecek kurumsal kimliği ve bu kimliğe uygun materyallerin tasarımlarını sizin için üstleniyoruz.",
    list4:
      "Müşterileriniz zihninde edineceğiniz yer, kurum kimliği ve firma algısının ilk adımı kurumsal kimlik tasarımlarınızla başlar.",
    prevUrl: "/blog-detay/1",
    nextUrl: "/blog-detay/3",
    prevBlog: "Ürün Fotoğraf Çekimi",
    nextBlog: "Sosyal Medya Yönetimi",
  },
  {
    id: 3,
    title: "Sosyal Medya Yönetimi",
    image: sosyalmedya,
    date: "12 Ağustos, 2023",
    shortDate: "12 Nov",
    // author: ["Joyce Knight"],
    readingTime: "6 Dk Okuma Süresi",
    view: "210 Görüntüleme",
    comment: "Yorum Yok",
    categories: ["Marka Yönetimi", "Reklam Kampanyası", "Sosyal Medya"],
    tags: ["Reklam Kampanyası", "Sosyal Medya", "Sosyal Medya Yönetimi"],
    details:
      "Bireysel kullanıcılar için sosyal medya yönetimi denince, sosyal medyada yayın yapmak ve güzel etkileşim alan gönderiler eklemek olarak nitelendirilebilir.",
    desc: "Bireysel kullanıcılar için sosyal medya yönetimi denince, sosyal medyada yayın yapmak ve güzel etkileşim alan gönderiler eklemek olarak nitelendirilebilir. Günümüzde gelişen ve globalleşen dünya düzeninin en merkezine sosyal medya ağları oturmuş durumda ve sosyal ağlarda yer almak bireysel kullanıcılar kadar, markalar ve şirketler için çok büyük önem kazanmıştır. Şirketler için sosyal medyada olmak ve burada stabil şekilde yapılan yayınlar bir fark oluşturmuyor ve kullanıcı için sıradan hale gelerek amacını kaybediyor. Sosyal medya araçlarını en düzgün şekilde kullanan şirketler, müşterileri ile en doğru iletişim kanallarını kullanan şirketler, büyük başarılar elde ediyor ve ürün- hizmet hedeflerine çok rahat bir şekilde ulaşabiliyorlar.",
    desctitle1: "",
    desc1:
      "Giderek daha fazla şirket müşterileriyle yakınlaşmak ve rekabeti kaybetmemek için sosyal medya hesapları oluşturuyor ve bu profillerin yönetimi için ekipler oluşturmakta ve en kısa sürede Sosyal ağlarda şirketini en güzel şekilde tanıtmak ve sonuç elde etmek istemektedir. Bununla birlikte, çoğu hedeflenmiş bir strateji olmadan ve sosyal ağların nasıl yönetileceğini çok iyi bilmeden bu işe başlamakta ve sonuç elde etmekten çok büyük bir hüsran ile karşılaşmaktadır",
    bloqnote:
      "Sosyal medya yönetimi uzman ve altyapısına hakim bir ekip tarafından yönetilmediği sürece şirket için bir prestij olmaktan daha çok prestij kaybına dahi sebep olmaktadır. Sosyal medyada yer almaya başlayan bir şirket için, hangi tür içeriğin yayınlanacağını, içeriğin kurumsal veya takipçi odaklı mı olacağını, sosyal medyada ne sıklıkta yayınlanması gerektiğini, hangi değerlerin analiz edileceğini bilmesi gereklidir. Belirtmiş olduğumuz bu verileri uzman ekibimizle en ince noktasına kadar planlayıp hareket etmekteyiz.",
    listtitle: "Sosyal medya yönetiminde sizlere şu hizmetleri sunmaktayız;",
    list1: "Sektöre özel strateji geliştirme",
    list2: "Sosyal Medya Paylaşımların Hazırlanması",
    list3: "Etkili ve Sonuç Odaklı Tasarımlar",
    list4: "Düzenli Raporlama",
    desctitle2: "Sosyal Medya Yönetimi Nedir?",
    desc2:
      "Sosyal medya yönetimi sadece gönderi paylaşımı yapmaktan çok daha fazlasıdır. Sadece içerik paylaşımı sonuç elde edilmiş olsaydı bu alanda bütün firmaların başarılar elde etmiş olması gerekirdi. Sosyal medyanın yönetimi, stratejinin tanımlanması ve konumlandırılması, yayınların planlanması ve sonuçların izlenmesi ve incelenmesine kadar çeşitli adımları içerir. Tüm bunların iyi bir şekilde yapılabilmesi için, bu kanaldan özel olarak sorumlu bir kişiye sahip olmak gerekir, şirket için önemli ve gerekli bir süreç olarak görülmesi gerekli kılınması sürecin en önemli aşamalarından bir tanesini oluşturmaktadır. Hangi sosyal ağlarda olmalıyım? Sosyal medyaya etkili bir yatırım yapma fikrini zaten sahipseniz, tam olarak ne yapmaya başlamanız gerektiğini merak ediyor olabilirsiniz. İlk adım, işletmenizin var olması için hangi sosyal ağların anlamlı olduğunu tanımlamaktır. Facebook gibi bazı sosyal ağlar, kitleleri nedeniyle, işletme türünden bağımsız olarak çok sayıda şirketin bulunmasını gerektirir. İnstagram gibi diğerleri, bir giyim e-ticaretine kurumsal hizmetler şirketine kıyasla çok daha fazla sonuç getirebilir. Önemli olan kitlenizin nerede olduğunu bulmaktır. Çoğu zaman öğrenmenin tek yolu test etmektir. Bu yöntemi kullanmak, zaman ve para kaybına neden olabilir. Yılların vermiş olduğu tecrübe ile sizi bu süreçlerden kurtarıyor ve şirketinizin veya markanızın hangi alanlarda yer alacağını analiz ediyor ve en ince detayları ile size sunuyoruz.",
    desc3:
      "Stratejimi sosyal medyada nasıl oluşturabilirim? İyi tanımlanmış bir strateji olmadan, şirketiniz sosyal ağlarda bir profile sahip olacak, ancak onunla ne yapacağını bilemeyecek. Burada birçok şirket hata yapıyor, bu yatırımın gerçek faydasını görmüyor ve bu alanda cesaretinin kırılmasına sebep oluyor. Sosyal medyada bulunma amacını tanımlamak çok önemlidir.Önemli olan tek bir hedefin stratejinizin ana ürünüz olması – odaklanmak – ve hedefin şirketiniz ile bağlantılı olmasıdır. En önemlisi, stratejinizin zaman içinde değişebilir olması ve bu değişim için en doğru zamanı bilmeniz gereklidir. Şirketinizin hedefine ulaştığını ve marka bilinirliğinin artık o kadar önemli olmadığını fark ederseniz, bir sonraki adım satışa odaklanmak olacaktır. Sosyal medya ağlarında iyi yapılan bir analiz ufak bir işletme dahi olsanız iyi bir strateji ile büyük şirketlerin yapamadığı işleri daha küçük işletmelerin yapabildiğini göreceksiniz.",
    prevUrl: "/blog-detay/2",
    nextUrl: "/blog-detay/4",
    prevBlog: "Kurumsal Kimlik Çalışması",
    nextBlog: "Seo Çalışması",
  },
  {
    id: 4,
    title: "Seo Çalışması",
    image: seo,
    date: "17 Temmuz, 2023",
    shortDate: "17 Temmuz",
    // author: ["Hazel Thomas"],
    readingTime: "5 Dk Okuma Süresi",
    view: "155 Görüntüleme",
    comment: "05 Yorum",
    categories: ["Marka Yönetimi", "Reklam Kampanyası"],
    tags: ["Google Arama Motoru", "Reklam", "Seo", "Seo Çalışması"],
    details:
      "Web sitenizin mobil uyumlu olması SEO çalışmaları için olmaz ise olmaz bir kriter. Bu yüzden öncelikli olarak web sitenizi mobil uyumlu hale getirdiğinizden emin olmalısınız. ",
    desc: "Web sitenizin mobil uyumlu olması SEO çalışmaları için olmaz ise olmaz bir kriter. Bu yüzden öncelikli olarak web sitenizi mobil uyumlu hale getirdiğinizden emin olmalısınız. Mobil uyumlu web siteleri, ziyaretçinin web sitesini ziyaret etiği cihaza göre kendisini otomatik olarak ekrana ayarlar ve ziyaretçinin web sitesini rahat kullanmasını ve görüntülemesini sağlar.",
    desctitle1: "Web Site Altyapınız SEO Dostu mu?",
    desc1:
      "Özellikle Google, ziyaretçilerin deneyimlerine oldukça önem vermekte. Yani web site ziyaretçiniz web sitenizi rahat görüntüler ve kullanabilir ise Google’da artı puanınız var demektir. Bunu web sitenize giren ziyaretçinin web sitenizden maksimum derecede faydalanabilir olması gibi düşünebilirsiniz.",
    bloqnote:
      "Web Siteniz Akıllı Url’ler ile mi Çalışmakta? SEO çalışmaları için bir diğer önemli altyapı konusu akıllı urllerdir. Web site içeriklerinize göre oluşturulan sayfa linkleri SEO dostu mu? Kısa olması ve karmaşık karakterler içermemesi gibi kurallara uygun mu? Örneğin Web Sitemiz Yayında gibi bir sayfanın urlsi şu şekilde olmalıdır: alanadi.com/web-sitemiz-yayinda ya da alanadi.com/haber/web-sitemiz-yayinda gibi. alanadi.com/sayfa.asp?id=199 şeklindeki urllerin eskide kaldığını ve SEO olarak olumsuz sonuçlar doğurabileceğini belirtelim.",
    desctitle2: "Site Haritası (Sitemap.xml) - Web Siteniz Hızlı Olmalı",
    desc2:
      "Arama motorlarının web site içeriklerini kolayca taradığı sitemap.xml dosyası web sitenizde mevcut mu? Mevcut ise eklediğiniz ya da sildiğiniz içeriklerin bu dosyaya otomatik olarak işlenip işlenmemesi de oldukça önemli bir konu. SEO açısından en faydalı çözüm bu dosyanın web site içeriklerine göre otomatik olarak güncellenmesidir. ",
    desc3:
      "Web siteniz çok hızlı açılmalı ve kullanıcılarınız sayfalar arası geçişlerde beklememelidirler. Bunun için de sunucu lokasyonu Türkiye’de olan ve üst düzey sunucu kullanan altyapıları tercih etmelisiniz. Ek olarak web sitenizde gereksiz css, script, görsel vb. dosyalarına yer vermemelisiniz. Web sitenizin hızı kullanıcı memnuniyetini doğrudan etkileyen bir özellik olduğundan SEO açısından çok ama çok önemlidir.",
    listtitle: "Altyapısal Diğer Detaylar",
    list1:
      "Web sitenizin mutlaka SSL sertifikasına sahip olması gereklidir. Yani web sitenizin https//… linki altında güvenli ve geçerli bir SSL sertifikası altında çalışıyor olması gerekli.",
    list2:
      "Eğer bir SSL sertifikasına sahip değilseniz SEO açısından zor durumda kalabilirsiniz. Bir an önce web sitenize bir SSL sertifikası edinmelisiniz. Bu konuda altyapı sağlayıcınızdan destek alabilirsiniz.",
    list3:
      "Web sitenizde favicon bulunması da SEO açısından küçük de olsa önemli bir unsur. Favicon internet tarayıcısı üzerinde web site urlnizin solunda yer alan simgedir.",
    list4:
      "Web sitenizde yer alan resimlerin bir alt değerine sahip olması oldukça önemli. Yani alt etiketi resimleriniz için otomatik oluşmalı ve yayınlanmalıdır. Bu resimlerinizin arama motorları tarafından ne anlama geldiğini, hangi içeriği beslediğini göstermesi açısından önemlidir.",
    prevUrl: "/blog-detay/3",
    nextUrl: "/blog-detay/5",
    prevBlog: "Sosyal Medya Yönetimi",
    nextBlog: "E-Ticaret Danışmanlık",
  },
  {
    id: 5,
    title: "E-Ticaret Danışmanlık",
    image: eticaret,
    date: "18 Haziran, 2023",
    shortDate: "18 Haziran",
    // author: ["Dale Shutt"],
    readingTime: "8 Dk Okuma Süresi",
    view: "135 Görüntüleme",
    comment: "03 Yorum",
    categories: ["Marka Yönetimi", "Reklam Kampanyası", "Web Tasarım"],
    tags: ["E Ticaret", "Web Site"],
    details:
      "E-Ticaret Danışmanlık hizmetimiz, çevrimiçi mağaza uygulama ve sanal mağaza projesinin tasarım ve planlamasını içerir.",
    desc: "E-Ticaret Danışmanlık hizmetimiz, çevrimiçi mağaza uygulama ve sanal mağaza projesinin tasarım ve planlamasını içerir: hedefler, iş planı, gereksinimler, özellikler, kaynaklar, zamanlama, lojistik, ekip, yatırım vb. Pazara girmenin ilk bölümünü profesyonel bir çalışma yapmak, markanın sunumundan siparişlerin yönetimine kadar tüm içeriğini ve farklılığını tüketici için tutarlı ve kolay bir şekilde göstermektir. Web sitenizin trafiği, sunduğunuz ürünle gerçekten ilgilenen ve ürünlerinizi / hizmetlerinizi satın almak isteyen detaylı bir analize sahip olmanız için ilk adım olacaktır. ",
    desctitle1:
      "E Ticaret Yazımınızı Kuruyoruz Entegrasyon Hizmetlerini Sunuyoruz Dijital Pazarlama Planı Her müşterinin hedeflerine uygun bir strateji ve planlama geliştiriyoruz.",
    desc1:
      "Web sitenizde nitelikli trafik elde etmek ve işletme hedeflerinize ulaşmak için Google Adwords’te kampanyalar yürütüyoruz. E-ticaretinizde sonuç elde etmek için dijital düzeyde rekabetin pratik olarak ürüne göre tanımlandığını anlamak çok önemlidir. Dijital ortamda insanlar hız, bilgi ve uzmanlık ararlar. Sonuç odaklı çalışan bir firma olarak bu özelliklerin hepsini barındırdığımızı belirtmek isteriz.",
    bloqnote:
      "E-ticaret ve Dijital Pazarlama Konusunda Uzmanlaşmış Bir Tam Hizmet Ajansı Projeniz için en iyi e-ticaret yazılım platformlarını sunuyoruz ve bunları en uygun altyapılarla hayat geçiriyoruz. E-ticaret projenizin büyümesini, yakından izleyin ve sürekli gelişim için uzman desteği ile de daha fazla satış yapın. Tüm değişkenler ölçülebilir ve bütçenizin ayrıntılı bir şekilde yönetilmesini sağlayarak yatırım getirisini artırır. Kampanyaların maliyetleri kitleye göre değil sonuçlara dayandırılmalıdır, bu da uzman ekibimizle birlikte, siteye erişim, olası satışlar veya satışlar açısından sonuçların elde edilmesini sağlar. Zaman içersinde E ticaret konusunda hızlı ve kararlı adımlar attığınızı size sunacağımız detaylı raporlar sayesinde göreceksiniz.",
    desctitle2: "",
    desc2: "",
    desc3: "",
    listtitle: "Müşteri Hizmetleri - Lojistik Ve Listeleme Hizmeti",
    list1:
      "Satın alma karar sürecini teşvik etmek ve desteklemek, satın almak konusunda merak ettiği soruların, takip bildirimlerini ve şikâyetleri sonuçlandırmak için E-posta, Sohbet, Telefon, Sosyal Ağlar gibi farklı kanallarla müşteri hizmetleri sunuyoruz.",
    list2:
      "E Ticaret Yazılımı kurmak veya onun reklamını yapmak gibi hizmetlerimizin yanında pazaryerlerinde (n11-hepsi burada vb) mağaza açma işlemleri, süreç takibi, ürünlerin listelenmesi, takip edilmesi ve detaylı analizlerin yapılması çalışmalarını yaparak şirketinize gerekli olan bütün altyapıyı sunuyoruz.",
    list3:
      "Sosyal medya yönetimi ile ürün ve hizmetlerinizle teklifinizin hedeflediği kullanıcılara ulaşmanıza olanak tanıyan kampanyalar (Facebook, Instagram ve Linkedin) geliştiriyoruz. ",
    list4:
      "Raporlarımız, sadece sayısal bir yaklaşım değil, nitel bir analize sahip olacak, böylece işletmeniz ve tüketiciniz hakkında faydalı bilgiler alabileceksiniz.",
    prevUrl: "/blog-detay/4",
    nextUrl: "/blog-detay/6",
    prevBlog: "Seo Çalışması",
    nextBlog: "Dijital Pazarlama",
  },
  {
    id: 6,
    title: "Dijital Pazarlama",
    image: dijitalpazarlama,
    date: "21 Mayıs, 2023",
    shortDate: "21 Mayıs",
    // author: ["Joyce Knight"],
    readingTime: "7 Dk Okuma Süresi",
    view: "225 Görüntüleme",
    comment: "08 Yorum",
    categories: ["Reklam Kampanyası", "Sosyal Medya"],
    tags: ["Design", "Development", "e-Learning"],
    details:
      "Dijital pazarlama; ürünlerinizin/hizmetlerinizin internet ortamında çeşitli araçlarla hedefinize uygun olarak tanıtma hizmetimizdir.",
    desc: "Dijital pazarlama; ürünlerinizin/hizmetlerinizin internet ortamında çeşitli araçlarla hedefinize uygun olarak tanıtma hizmetimizdir.",
    desctitle1: "",
    desctitle2: "Sizin de dijital ortamda;",
    desc2:
      "Hizmetinizden/Ürünlerinizden daha fazla kişi haberdar olsun, Geniş bir kitleye ulaşarak marka bilinirliğiniz artsın, Potansiyel müşterilerden geri dönüş elde edilsin, Web site trafiğiniz artsın, Potansiyel müşteriyi harekete geçirici reklam kampanyası oluşturulsun, Kitle hedeflemeleri ile bütçeniz daha efektif kullanılsın.",
    desc3: "",
    bloqnote:
      "Dijitalleşen dünyada kullanıcıların çeşitli cihazlarda çevrimiçi olarak var olmalarının artış göstermesi sebebiyle dijital pazarlama, geleneksel pazarlamaya göre çok daha avantajlı bir konuma gelmiştir. Ayrıca verim oranı da yapılan dijital pazarlama çalışmalarının yönetilmesinin uzmanlığına göre artış göstermektedir.",

    listtitle: "Dijital Pazarlama Kanallarımız",
    list1: "Google Ads (Arama Motoru Reklamı)",
    list2: "Görüntülü Reklam Ağı (Yeniden Pazarlama, Youtube Reklamları)",
    list3: "Sponsorlu Reklamlar (İnstagram, Facebook, Linkedin)",
    list4: "Arama Ağı",
    prevUrl: "/blog-detay/5",
    nextUrl: "/blog-detay/1",
    prevBlog: "E-Ticaret Danışmanlık",
    nextBlog: "Ürün Fotoğraf Çekimi",
  },
  // {
  //   id: 7,
  //   title: "Brave for Others And Brave for Yourself",
  //   image: "post-07.jpg",
  //   date: "24 Nov, 2022",
  //   shortDate: "24 Nov",
  //   author: ["Dale Shutt"],
  //   readingTime: "9 Min Read",
  //   view: "185 View",
  //   comment: "05 Comments",
  //   categories: ["Web Development", "Marketing"],
  //   tags: ["Art", "UI Design", "Finance"],
  //   details:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // },
  // {
  //   id: 8,
  //   title: "An Excellent Foundation for Future Success",
  //   image: "post-08.jpg",
  //   date: "26 Nov, 2022",
  //   shortDate: "26 Nov",
  //   author: ["Hazel Thomas"],
  //   readingTime: "3 Min Read",
  //   view: "147 View",
  //   comment: "07 Comments",
  //   categories: ["Education", "UI/UX Design"],
  //   tags: ["Design", "e-Learning", "Online Courses"],
  //   details:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // },
  // {
  //   id: 9,
  //   title: "Committed to Excellence in Teaching",
  //   image: "post-09.jpg",
  //   date: "28 Nov, 2022",
  //   shortDate: "28 Nov",
  //   author: ["Joyce Knight"],
  //   readingTime: "8 Min Read",
  //   view: "174 View",
  //   comment: "No Comments",
  //   categories: ["UI/UX Design", "Business"],
  //   tags: ["UI Design", "e-Learning", "Finance"],
  //   details:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // },
  // {
  //   id: 10,
  //   title: "Changing Lives and Giving Choices For Everyone",
  //   image: "post-10.jpg",
  //   date: "13 Nov, 2022",
  //   shortDate: "13 Nov",
  //   author: ["Hazel Thomas"],
  //   readingTime: "4 Min Read",
  //   view: "140 View",
  //   comment: "05 Comments",
  //   categories: ["Education", "Marketing"],
  //   tags: ["Development", "e-Learning", "Online Courses"],
  //   details:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // },
  // {
  //   id: 11,
  //   title: "Good Things are Happening Creative Learning",
  //   image: "post-11.jpg",
  //   date: "16 Nov, 2022",
  //   shortDate: "16 Nov",
  //   author: ["Hazel Thomas"],
  //   readingTime: "5 Min Read",
  //   view: "112 View",
  //   comment: "03 Comments",
  //   categories: ["Marketing", "Online Learning"],
  //   tags: ["Course", "Education", "Finance"],
  //   details:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // },
  // {
  //   id: 12,
  //   title: "Creating a Community of Diverse Learners",
  //   image: "post-12.jpg",
  //   date: "25 Nov, 2022",
  //   shortDate: "25 Nov",
  //   author: ["John Kite"],
  //   readingTime: "6 Min Read",
  //   view: "131 View",
  //   comment: "08 Comments",
  //   categories: ["Online Learning", "Education"],
  //   tags: ["Consulting", "e-Learning", "Finance"],
  //   details:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  // },
];

export default BlogData;
