import React from "react";
import Slider from "react-slick";
import { TestimonialOneParams } from "../../utils/script";
import adlibilişim from "./adlibilişim.jpg";
import hedefoniki from "./hedefoniki.jpg";

let data = [
  {
    name: "Adli Bilişim ve Hukuk",
    details: "Sosyal medya yönetimi.",
    // designation: "SEO Expert",
    image: adlibilişim,
  },
  {
    name: "Hedef Oniki",
    details: "Logo Çalışması.",
    // designation: "WordPress Expert",
    image: hedefoniki,
  },

  //   {
  //     name: "Julia H. Smith",
  //     details:
  //       "The placeholder text, beginning with the line “Lorem ipsum dolor sit amet, consectetur adipiscing elit”, looks like Latin because in its youth, centuries ago, it was Latin. In particular, the garbled words of lorem ipsum bear an unmistakable resemblance.",
  //     designation: "Laravel Developer",
  //     image: "client-04.png",
  //   },
];

const TestimonialOne = (props) => {
  const classes = `testimonial-activation testimonial-style-1 edu-slick-button slick-button-left ${
    props.className ? props.className : ""
  }`;
  data = props.item ? data.slice(0, props.item) : data;
  return (
    <Slider className={classes} {...TestimonialOneParams}>
      {data.map((item, i) => (
        <div className="single-testimonial" key={i}>
          <div className="inner" style={{ textAlign: "center" }}>
            {/* {props.quoteIcon !== "disable" && (
              <div className="quote-sign">
                <img
                  src="/images/testimonial/testimonial-01/quote.png"
                  alt="Quote Images"
                />
              </div>
            )} */}
            <div
              className="client-information"
              style={{
                justifyContent: "center",
              }}
            >
              <div className="thumb">
                <img
                  style={{
                    marginBottom: "1rem",
                    justifyContent: "center",
                    width: "150px",
                    height: "150px",
                  }}
                  src={item.image}
                  alt="Client Thumb"
                />
              </div>
            </div>
            <div className="info">
              <h5 className="title">{item.name}</h5>
              <span className="designation">{item.designation}</span>
            </div>
            <p className="description">{item.details}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
};
export default TestimonialOne;
