import React from "react";
import { Link } from "react-router-dom";

const AboutFive = ({ wrapperClass }) => {
  return (
    <div
      className={`home-three-about edu-about-area about-style-4 bg-color-white ${
        wrapperClass || "edu-section-gapBottom"
      }`}
    >
      <div className="container eduvibe-animated-shape">
        <div className="row g-lg-5 g-md-5 g-sm-5">
          <div className="col-lg-12 col-xl-6">
            <div className="gallery-wrapper">
              <img
                src="/images/about/about-05/about-group-01.jpg"
                alt="About Images"
              />
            </div>
          </div>
          <div className="col-lg-12 col-xl-6">
            <div className="inner mt_mobile--40">
              <div className="section-title text-start">
                <span className="pre-title">BİZ KİMİZ</span>
                <h3 className="title">Neden Mor Kelebek Ajans</h3>
              </div>
              <div className="feature-style-6 mt--40">
                <div className="edu-feature-list color-variation-1">
                  <div className="icon">
                    <img
                      src="/images/about/about-05/expert.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">Alanında Uzman Çalışanlar</h6>
                    <p className="description">
                      Mor Kelebek Ajans ile alanında uzman çalışanlar tarafından
                      işleriniz titizlikle takip edilir.
                    </p>
                  </div>
                </div>

                <div className="edu-feature-list color-variation-2">
                  <div className="icon">
                    <img
                      src="/images/about/about-05/rocket.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">Kreatif Fikirler</h6>
                    <p className="description">
                      Yaratıcı fikirler ile firma ve markanız rakiplerinden
                      sıyrılsın ve öne geçsin.
                    </p>
                  </div>
                </div>

                <div className="edu-feature-list color-variation-3">
                  <div className="icon">
                    <img
                      src="/images/about/about-05/time.png"
                      alt="Icons Images"
                    />
                  </div>
                  <div className="content">
                    <h6 className="title">Zamanında Teslim</h6>
                    <p className="description">
                      Kaliteye ve müşteri memnuniyetine önem vererek istenilen
                      sürede projeleri teslim eder.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="read-more-btn mt--30">
              <Link className="edu-btn" to="/bize-ulasin">
                Bize Ulaşın <i className="icon-arrow-right-line-right"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="shape-image shape-image-1">
            <img src="/images/shapes/shape-03-08.png" alt="Shape Thumb" />
          </div>
          <div className="shape-image shape-image-2">
            <img src="/images/shapes/shape-27.png" alt="Shape Thumb" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
