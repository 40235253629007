import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return (
    <p className="success-message">
      Bizimle iletişime geçtiğiniz için çok teşekkür ederiz. Sizinle en kısa
      sürede irtibata geçeceğiz.
    </p>
  );
};

function ContactUsForm({ formStyle }) {
  const [result, setResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_vw0hzbd",
        "template_p9qyg69",
        e.target,
        "q5aOMHLOP6voAvbYD"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    console.log("e target", e.target);
    e.target.reset();
    setResult(true);
  };

  setTimeout(() => {
    setResult(false);
  }, 10000);

  return (
    <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="fullname"
            placeholder="İsminiz*"
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-lg"
            name="email"
            placeholder="E-mail Adresiniz*"
            required
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="phone"
            placeholder="Telefon Numaranız"
          />
        </div>
      </div>

      <div className="col-lg-12">
        <div className="form-group">
          <input
            type="text"
            className="form-control form-control-lg"
            name="subject"
            placeholder="Konu"
          />
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-group">
          <textarea
            className="form-control"
            name="message"
            placeholder="Mesajınız"
          ></textarea>
        </div>
      </div>
      <div className="col-lg-12">
        <button className="rn-btn edu-btn w-100" type="submit">
          <span>Gönder</span>
          <i className="icon-arrow-right-line-right"></i>
        </button>
      </div>
      {result ? (
        <div className="form-group">
          <Result />
        </div>
      ) : null}
    </form>
  );
}
export default ContactUsForm;
