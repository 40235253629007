import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import AboutUsThree from "./pages/innerpages/AboutUsThree";
import BlogStandard from "./pages/blog/BlogStandard";
import BlogDetailsThree from "./pages/detailspages/BlogDetailsThree";
import CategoryArchive from "./pages/archive/CategoryArchive";
import TagArchive from "./pages/archive/TagArchive";
import ContactUs from "./pages/innerpages/ContactUs";
import CourseOne from "./pages/course/CourseOne";
import HomeThree from "./pages/homepages/HomeThree";
import Testimonial from "./pages/innerpages/Testimonial";
import Error from "./pages/innerpages/Error";

// Import Css Here
import "./assets/scss/style.scss";

function App() {
  return (
    <Router basename="/">
      <ScrollToTop>
        <Routes>
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/"}`}
            element={<HomeThree />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/hakkimizda"}`}
            element={<AboutUsThree />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/bize-ulasin"}`}
            element={<ContactUs />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/hizmetlerimiz"}`}
            element={<CourseOne />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/referanslar"}`}
            element={<Testimonial />}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/blog"}`}
            element={<BlogStandard />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/blog-detay/:id"}`}
            element={<BlogDetailsThree />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/kategori/:slug"}`}
            element={<CategoryArchive />}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL + "/etiket/:slug"}`}
            element={<TagArchive />}
          />
          <Route path="*" element={<Error />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
