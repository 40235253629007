import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import PostStandard from "../../components/post/PostStandard";
import SearchOne from "../../components/widgets/blog/SearchOne";
import CategoryOne from "../../components/widgets/blog/CategoryOne";
import LatestPostOne from "../../components/widgets/blog/LatestPostOne";
import TagOne from "../../components/widgets/blog/TagOne";
import PostData from "../../data/blog/BlogData.js";
const BlogStandard = () => {
  return (
    <>
      <SEO title="Blog - Mor Kelebek Ajans" />
      <Layout>
        <BreadcrumbOne
          title="Blog - Mor Kelebek Ajans"
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl="Blog"
        />
        <div className="edu-elements-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8 eduvibe-blog-standard-wrapper">
                {PostData.map((item) => (
                  <div
                    className="edu-blog blog-type-1 radius-small eduvibe-post-standard mt--40"
                    key={item.id}
                  >
                    <PostStandard data={item} />
                  </div>
                ))}
              </div>
              <div className="col-lg-4">
                <aside className="edu-blog-sidebar">
                  <SearchOne />
                  <CategoryOne extraClass="mt--40" />
                  <LatestPostOne extraClass="mt--40" />

                  <TagOne extraClass="mt--40" />
                </aside>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogStandard;
