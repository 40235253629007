import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const items = [
  {
    title: "Sosyal Medya Yönetimi",
    info: "Sosyal medya araçlarını en düzgün şekilde kullanan şirketler, müşterileri ile en doğru iletişim kanallarını kullanan şirketlerdir.",
    icon: "icon-student-read",
    link: "#",
  },
  {
    title: "Web Tasarım",
    info: "Kurumunuzun internetteki temsilcisi web sitenizdir. Kurumsal web tasarımınız etkili ve ilgi çekici ise geri dönüşüm etkisi fazla olur.",
    icon: "icon-lab",
    link: "#",
  },
  {
    title: "E-Ticaret Siteleri",
    info: "E-ticaret altyapısı; firmanızın ürün veya hizmetlerinin, dijital ortamda potansiyel müşterileriniz olan tüketicilerle buluşmasını sağlar.",
    icon: "icon-compass",
    link: "#",
  },
  {
    title: "Video Prodüksiyon",
    info: "Şirketiniz ve ürünleriniz için Özel Video Prodüksiyon Hizmeti sunmaktan ziyade bu işin bütün kalemleri ile ele alıyoruz.",
    icon: "icon-calculator",
    link: "#",
  },
  {
    title: "Ürün Fotoğraf Çekimi",
    info: "İnternet üzerinden ürünlerinizi satmayı planlıyorsanız, ürünlerinizin fotoğraflarının kalitesi müşterinin satın almaya karar vermesi için belirleyici bir faktördür.",
    icon: "icon-microscopes",
    link: "#",
  },

  {
    title: "Grafik Tasarım",
    info: "Tasarımın insanların yaşamlarını olumlu etkileyebileceğini ve markalarla ilişkilerini değiştirebileceğini göstermek istiyoruz.",
    icon: "icon-pen-tool",
    link: "#",
  },
  {
    title: "Kurumsal Kimlik Çalışması",
    info: "Mor Kelebek Ajans; marka ve firmaların sahip olduğu nitelikleri, ürünleri ve hizmetleri hedef kitleye sunabilmek için benzersiz bir görsel kimlik yaratımı oluşturur.",
    icon: "icon-microscopes",
    link: "#",
  },
];

const CategoryTwo = ({ wrapperClass, styleClass, initiallyVisible }) => {
  return (
    <div className={`row ${wrapperClass || "g-5 mt--25"}`}>
      {items.map((data, i) => (
        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          initiallyVisible={initiallyVisible}
          className={`col-lg-3 col-md-6 col-sm-6 col-12 ${
            styleClass ? styleClass : ""
          }`}
          animateOnce={true}
          key={i}
        >
          <div className="service-card service-card-4">
            <div className="inner">
              <div className="icon">
                <i className={data.icon}></i>
                <span className="subtitle">{data.numberOfCourses}</span>
              </div>
              <div className="content">
                <h6 className="title">
                  <a href={data.link}>{data.title}</a>
                </h6>
                <p className="description">{data.info}</p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}
    </div>
  );
};

export default CategoryTwo;
