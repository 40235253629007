import React from "react";

const AboutUsThreeMission = () => {
  return (
    <div className="eduvibe-about-three-mission edu-mission-vision-area edu-section-gap border-bottom-1 bg-color-white">
      <div className="container eduvibe-animated-shape">
        <div className="row">
          <div className="col-lg-12">
            <div className="thumbnail mb--50">
              <div className="shape-image image-2 me-5">
                <img
                  src="/images/mission/mission-gallery-02.jpg"
                  alt="Gallery Images"
                />
              </div>

              <div className="col-lg-6">
                <div className="our-mission">
                  <div className="section-title text-center">
                    <h3 className="title">Mor Kelebek Ajansa Hoş Geldiniz</h3>
                    <p className="mt--30 mb--0">
                      Mor Kelebek Ajans 2014 yılından bugüne kadar müşterilerine
                      Web Tasarım, Web yazılım, Grafik Tasarım, Toplu Mail
                      Gönderimi, Sosyal Medya Yönetimi,Video Prodüksiyon,
                      Fotağraf Çekimi Seo ve E-Ticaret uygulamaları konusunda
                      profesyonel olarak hizmet vermektedir. Temel prensibi,
                      müşterisine en iyi hizmeti sunmak ve müşteri memnuniyetini
                      en üst düzeyde tutmak için çalışmaktır. Mor Kelebek Ajans,
                      sürekli değişen ve gelişen pazarda, sektörün ihtiyaçlarını
                      analiz edip her müşterisine farklı çözümler sunmaktadır.
                      İşletmelere inovatif, kalıcı çözümler sunarak kurumların
                      değişime adapte olup ve bu süreci yönetmelerine yardımcı
                      olmaktadır. Kendine güvenen, alanında uzman, tecrübeli,
                      donanımlı, profesyonel çalışan ama bir o kadar da amatör
                      ruha sahip bir ekip ile yola çıktık.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
          <div className="animated-image shape-image-1">
            <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
          </div>
          <div className="shape shape-1">
            <span className="shape-dot"></span>
          </div>
          <div className="shape shape-2">
            <span className="shape-dot"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsThreeMission;
