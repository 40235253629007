import React from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { slugify } from "../../utils";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import SearchOne from "../../components/widgets/blog/SearchOne";
import CategoryOne from "../../components/widgets/blog/CategoryOne";
import LatestPostOne from "../../components/widgets/blog/LatestPostOne";
import TagOne from "../../components/widgets/blog/TagOne";
import Comment from "../blog/Comment";
import PostData from "../../data/blog/BlogData";

const BlogDetailsThree = () => {
  const { id } = useParams();
  const blogId = parseInt(id, 10);
  const dataItem = PostData.filter((blog) => blog.id === blogId);
  const data = dataItem[0];
  return (
    <>
      <SEO title={data.title} />
      <Layout>
        <BreadcrumbOne
          title={data.title}
          rootUrl="/"
          parentUrl="Anasayfa"
          currentUrl={data.title}
        />
        <div className="edu-blog-details-area edu-section-gap bg-color-white">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-8">
                <div className="blog-details-1 style-variation2">
                  <div className="content-blog-top">
                    <div className="thumbnail">
                      <img
                        className="radius-small w-100 mb--30"
                        src={data.image}
                        alt="Blog Thumb"
                      />
                    </div>
                    <ul className="blog-meta">
                      <li>
                        <i className="icon-calendar-2-line"></i>
                        {data.date}
                      </li>
                      <li>
                        <i className="icon-discuss-line"></i>
                        {data.comment}
                      </li>
                      <li>
                        <i className="icon-time-line"></i>
                        {data.readingTime}
                      </li>
                    </ul>
                    <h4 className="title">{data.title}</h4>
                  </div>

                  <div className="blog-main-content">
                    <p>{data.desc}</p>
                    <h5 className="title">{data.desctitle1}</h5>
                    <p>{data.desc1}</p>
                    <blockquote className="blockquote-style-1">
                      {data.bloqnote}
                      <img
                        className="quote-image"
                        src="/images/icons/quote-2.png"
                        alt="Quote Images"
                      />
                    </blockquote>

                    <h5 className="title">{data.listtitle}</h5>

                    <ul className="list-style-2">
                      <li>{data.list1}</li>
                      <li>{data.list2}</li>
                      <li>{data.list3}</li>
                      <li>{data.list4}</li>
                    </ul>

                    <h5>{data.desctitle2}</h5>
                    <p>{data.desc2}</p>
                    <p>{data.desc3}</p>
                  </div>

                  <div className="blog-tag-and-share mt--50">
                    {data.tags && data.tags.length > 0 && (
                      <div className="blog-tag">
                        <div className="tag-list bg-shade">
                          {data.tags.map((tag, i) => {
                            return (
                              <Link
                                key={i}
                                to={
                                  process.env.PUBLIC_URL +
                                  `/tag/${slugify(tag)}`
                                }
                              >
                                {tag}
                              </Link>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="eduvibe-post-share">
                      <span>Paylaş: </span>
                      <a className="linkedin" href="#">
                        <i className="icon-linkedin"></i>
                      </a>
                      <a className="facebook" href="#">
                        <i className="icon-Fb"></i>
                      </a>
                      <a className="twitter" href="#">
                        <i className="icon-Twitter"></i>
                      </a>
                      <a className="youtube" href="#">
                        <i className="icon-youtube"></i>
                      </a>
                    </div>
                  </div>

                  <div className="blog-pagination">
                    <div className="row g-5">
                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2">
                          <a href={data.prevUrl}>
                            <i className="ri-arrow-left-s-line"></i>
                            <span>{data.prevBlog}</span>
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="blog-pagination-list style-variation-2 next-post">
                          <a href={data.nextUrl}>
                            <span>{data.nextBlog}</span>
                            <i className="ri-arrow-right-s-line"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="edu-comment-form mt--50">
                    <Comment url="" id={data.id} title={data.title} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="edu-blog-sidebar">
                  <SearchOne style2="enable" />
                  <CategoryOne extraClass="mt--50" style2="enable" />
                  <LatestPostOne extraClass="mt--50" style2="enable" />

                  <TagOne extraClass="mt--50" style2="enable" />
                </aside>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogDetailsThree;
